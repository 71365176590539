<template>
   <div class="Deposit">
            <div class="bg"></div>
            <div class="content">
                <headbox title="交押金" :isbackPath="true"  path="pricingDeposit"  bgColor="#fff"></headbox>

                 <div class="bigBox">
                        <div class="box bank flex">
                            <div class="bankIcon"></div>
                            <div class="bankText">
                                <h4>银行转账</h4>
                                <p>使用网银支付转账，无手续费，需人工确认</p>
                            </div>
                            <div class="radio">
                                   <el-checkbox v-model="checked" disabled></el-checkbox>
                            </div>
                        </div>
                        <div class="box bankInfo">
                            <div class="bankCard"  :class="{
                            ICBCbankBg:bankBg.ICBC,
                            ABCbankBg:bankBg.ABC,
                            BOCbankBg:bankBg.BOC,
                            CMBbankBg:bankBg.CMB,
                            CCBbankBg:bankBg.CCB,
                            PABbankBg:bankBg.PAB,
                            }">
                                <div class="bankName flex">
                                    <div class="name">
                                        <h4 class="">{{Banklist.name}}</h4>
                                        <p>{{Banklist.address}}</p>
                                    </div>
                                    <div class="capy" @click="copy(Banklist.name)">复制</div>
                                </div>
                                <div class="CardNumber flex_between">
                                   <div>{{Banklist.num}}</div>
                                   <div class="capyCard" @click="copy(Banklist.num)">复制</div>
                                </div>
                            </div>
                            <div class="bankTip">
                                您必须向发卡银行确认您的银联卡是否已开通网上支付业务功能，才可以选择此方式付款，请您在支付后上传付款截图。
                            </div>
                            <van-field v-model="edpositNb" type="digit" :border="false" label="金额:"  placeholder="仅支持整千金额"/>
                             <h4 class="screenshot">上传转账截图：</h4>
                             <van-uploader v-model="fileList" :after-read="afterRead"   multiple :max-count="3" preview-size="90" border="false">
                                        <div class="file">
                                           <div class="one"></div>
                                           <div class="two"></div>
                                        </div>
                             </van-uploader>
                             <div class="tips">温馨提示：转款不要备注，谢谢！</div>
                        </div>
                        <footer class="flex">
                                <div class="btn close" @click="close">取消</div>
                                <div class="btn confirm" @click="confirm">确认</div>
                        </footer>
                 </div>

            </div>
   </div>
</template>

<script>
import headbox from '@/components/head.vue'
import *as api from'@/utils/api'
import axios from 'axios'
export default {
    components:{
        headbox
    },
    data() {
        return {
            checked:true,
            edpositNb:'' ,// 交金额数
             // 已上传的文件列表
             fileList: [],
            //  上传图片至服务器后返回的key值
             fileKey:[],
            fileBaseUrl:process.env.NODE_ENV == "dev"
                ? "/shenzhenyht"
                : "https://shenzhenyht.oss-cn-shenzhen.aliyuncs.com",
            localtoken:localStorage.getItem("token"),
            Banklist:{},
            bankBg:{
                ABC:false,//农业
                BOC:false,//中国银行
                CMB:false,//招商
                ICBC:false,//工商
                CCB:false,//建设
                PAB:false,//平安
                },
            bankIcon:[
                {name:'农业银行',ABC:false},//农业
                {name:'中国银行',BOC:false},//中国银行
                {name:'招商银行',CMB:false},//招商
                {name:'工商银行',ICBC:false},//工商
                {name:'建设银行',CCB:false},//建设
                {name:'平安银行',PAB:false},//平安
                ],
          confirmStatus: false
        }
    },
    mounted() {
        this.getBanklist()
    },
    methods:{
        // 确认按钮事件
async  confirm(){
            // 判断提交金额是否是1000的倍数
            if (this.edpositNb%1000 ==0 && this.edpositNb != 0) {
                // 是1000的倍数
                // 判断是否上传截图
                    if (this.fileList.length) {
                      if(this.confirmStatus){
                        let res = await api.returnDeposit({
                          type:'DEPOSIT', //参数为REFUND 是退押金类型
                          money:this.edpositNb, //退款金额
                          payedInfo: this.fileKey
                        })
                        this.edpositNb = '' //清空交押金金额数
                        this.fileList = [] //清空已上传的文件列表
                        this.fileKey = []  //清空文件key值
                        this.$router.push({
                          name:'depositConfirm'
                        })
                      } else {
                        this.$toast('请等待转账截图证明上传完成')
                      }
                    }else {
                        // 没有上传
                       this.$toast('请上传转账截图证明')
                    }

            }else {
                this.$toast('请输入正确的金额')
            }
        },
        // 取消按钮事件
        close(){
            this.$router.push({
                name:'pricingDeposit'
             })
        },
        // // 文件删除前的回调函数
        // beforeDelete(){
        //         console.log('111');
        //         this.fileList = []
        //         this.fileKey =[]
        // },
        // 文件上传完毕之后的回调,获取到对应的 file 对象
       async  afterRead(file){
        console.log('1',file);
         this.confirmStatus = false
             const that = this;
                const data = {
                       size: 1
                     };
            api.apiFile(data).then((res) => {
                console.log('res',res);
                this.fileKey.push(res[0].key);
                console.log('key',this.fileKey);
                console.log(this.fileList);
                that.uploadFile(file.content, res[0].url);
        });
        },
        // 第二步上传文件
        uploadFile(imgpath,url) {
            // debugger;
            // 去掉url域名 保留后面
            const urllast =
              "/" +
              url
                .split("/")
                .slice(3)
                .join("/");
            const configs = {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + this.localtoken
              }
            };
            const transformRequest = [
              function(data) {
                // debugger;
                let ret = "";
                for (const it in data) {
                  ret +=
                    encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                }
                return ret;
              }
            ];
            const imgarr = this.base64ToUint8Array(imgpath);
            axios({
              url: this.fileBaseUrl + urllast,
              method: "put",
              headers: {
                "Content-Type": "application/octet-stream",
                Authorization: "Bearer " + this.localtoken
              },
              data: imgarr
            }).then(res => {
              this.confirmStatus = true
              console.log("请求结果：", res);
            });

            // axios.put(this.fileBaseUrl+urllast,imgarr,{configs,transformRequest}).then(res=>{
            //   console.log(res)
            // })
            // certifi.updaFileapi(this.fileBaseUrl+urllast,imgpath,{}).then(res=>{
            //   console.log(res)
            // })
        },
        base64ToUint8Array(base64String) {
         // debugger;
         const base64clear = base64String.substring(base64String.indexOf(",") + 1);
         const padding = "=".repeat((4 - (base64clear.length % 4)) % 4);
         const base64 = (base64clear + padding)
           .replace(/\-/g, "+")
           .replace(/_/g, "/");
         const rawData = window.atob(base64);
         const outputArray = new Uint8Array(rawData.length);

         for (let i = 0; i < rawData.length; ++i) {
           outputArray[i] = rawData.charCodeAt(i);
         }
         return outputArray;
        },
         // 复制功能
        copy(txt){
            this.$copyText(txt).then(()=>{
                this.$toast('卡号已复制到剪贴板')
            })
        },
                // 获取系统银行卡信息
        async getBanklist(){
        let res = await api.banklist()
        console.log('res',res);
        // if (res.length == 0) {
        //         // 代表没有数据 则没有银行卡信息 需要去设置指定收款账户
        //     console.log('需跳转设置指定收款账户');
        // }
        this.Banklist = res[0].bankCard
        this.distinguish(this.Banklist.bank)
        },
        // 分辨银行名称
        distinguish(bank){

                this.bankIcon.forEach((item,i)=>{
                   if (item.name == bank) {
                        Object.keys(item).forEach(key =>{
                            key != 'name'? item[key] = true:item[key] = item.name
                        })
                   }
                })
              Object.keys(this.bankBg).forEach(key=>{
                            this.bankIcon.forEach(item=>{
                                 Object.keys(item).forEach(k=>{
                                    if (k != 'name') {
                                        if (key == k) {
                                            this.bankBg[key] = item[k]
                                        }
                                    }

                                 })
                     })
              })
              console.log(this.bankBg);
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
    .el-checkbox__inner{
        border-radius: 50%;
        width: 16px;
        height: 16px;
    }
    .el-checkbox__inner:after{
            left: 5px;
            height: 8px;
    }
    .van-field__label{
        width: 100px;
    }
    .van-cell__title{
        color: #242424;
        font-size: 32px;
        font-weight: 500;

    }
    .van-cell__value {
        color: #999999;
        font-size: 32px;
        top: -10px;
    }
    .van-uploader__preview{
    margin: 0 !important;
        .van-image{
            height: 100% !important;
            width: 156px !important;
            }
        .van-uploader__preview-delete {
                top: 0;
                right: 0;
                width: 40px;
                height: 40px;
                background-color: rgba(0,0,0,.7);
                border-radius: 0 0 0 40px;
                .van-icon  {
                position: absolute;
                top: -10px;
                right: -14px;
                color: #fff;
                font-size: 40px;
                -webkit-transform: scale(.5);
                transform: scale(.5);
            }
            }

    }
    .van-cell{
        padding-left: 0px !important;
    }
    .van-uploader__preview-delete{
        width:30px !important;
        height: 30px !important;
    }
    .van-icon{
        right: -8px !important;
    }
}
.bg{
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.flex{
    display: flex;
}
.box{
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    margin-top: 30px;
}
.screenshot{
    margin: 10px 0 20px;
    font-size: 32px;
    color: #242424;
}
.content{
    position: relative;
    z-index: 1;
    .bigBox{
        width: 100%;
        padding: 0px 40px;
        .bank{
                padding: 30px;
                .bankIcon{
                    width: 52px;
                    height: 40px;
                    background: url('~@/assets/images/icon_transfer_bank@2x.png');
                    background-size: 100%;
                    margin-top: 20px;
                }
                .bankText{
                    margin: 0 40px 0 30px;
                    h4{
                        font-weight: 500;
                        font-size: 32px;
                        color: #242424;
                    }
                    p{
                        font-weight: 400;
                        color: #666666;
                        font-size: 24px;
                        padding: 6px 0;
                    }
                }
                .radio{
                    width: 30px;
                    padding: 22px 0;
                }
        }
        .bankInfo{
            padding:  50px 30px 40px;
            .bankCard{
                width: 100%;
                height: 300px;
                padding: 50px 20px;
                background-size: 100% 100%;
                background: url('~@/assets/images/pic_bank_nor@2x.png');
                background-size: 100%;
                .bankName{
                    padding-left: 100px;
                    .name{
                        width: 400px;
                         color: #FFFFFF;
                         padding: 10px 0;
                        h4{
                            font-weight: 600;
                            font-size: 28px;
                        }
                        p{
                            font-weight: 400;
                            font-size: 28px;
                            padding: 16px 0 0;
                        }
                    }
                }
                .CardNumber{
                    font-size: 38px;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-top: 40px;
                    padding-left: 12px;
                }
            }
            .bankTip{
                color: #666666;
                line-height: 41px;
                font-size: 28px;
                margin: 30px 0;
            }
        }
    }
}
.file{
        position: relative;
        width: 150px;
        height: 150px;
        border: 1px dashed #ccc;
        .one{
            width: 90px;
            height: 1px;
            background-color: #ccc;
            position: absolute;
            top: 50%;
            left: 30px;
        }
        .two{
            width: 1px;
            height: 90px;
            background-color: #ccc;
            position: absolute;
            top: 32px;
            left: 50%;
        }

}
.tips{
    margin: 14px 0;
    color: #FF2D2E;
    font-size: 24px;
}
footer{
    padding: 50px 40px;
    width: 100%;
    .btn{
        width: 280px;
        height: 72px;
        text-align: center;
        line-height: 72px;
        color: #FFFFFF;
        font-size: 28px;
        border-radius: 50px;
    }
    .close{
        background: #D1D1D1;
    }
    .confirm{
       background: #FF2D2E;
       margin-left: 60px;
    }
}
.capy{
    width: 64px;
    height: 32px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    font-size: 24px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    margin-top: 12px;
}
.capyCard{
    width: 64px;
    height: 32px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    font-size: 24px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    margin-top: 11px;

}
.flex_between{
    display: flex;
    justify-content: space-between;
}
.ICBCbankBg{
        background: url('~@/assets/images/pic_bank_ICBC@2x.png') !important;
        background-size: 100% !important;

}
//
.ABCbankBg{
        background: url('~@/assets/images/pic_bank_ABC@2x.png') !important;
        background-size: 100% !important;

}
.BOCbankBg{
        background: url('~@/assets/images/pic_bank_China@2x.png') !important;
        background-size: 100% !important;

}
.CMBbankBg{
        background: url('~@/assets/images/pic_bank_CMB@2x.png') !important;
        background-size: 100% !important;

}
.CCBbankBg{
        background: url('~@/assets/images/pic_bank_CCB@2x.png') !important;
        background-size: 100% !important;

}
.PABbankBg{
        background: url('~@/assets/images/pic_bank_nor@2x.png') !important;
        background-size: 100% !important;

}

</style>
